<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="#212529"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="inherit"
        d="M1.98844 7.7297L25.5005 2.4292L49.0157 7.7297C49.0157 7.7297 49.288 18.4282 45.7032 31.6703C42.1184 44.9124 25.5005 50.4292 25.5005 50.4292C25.5005 50.4292 8.88258 44.9124 5.29776 31.6703C1.71294 18.4282 1.9852 7.7297 1.9852 7.7297H1.98844ZM25.613 10.8772L39.8403 14.17C39.6761 17.3092 39.1642 22.858 37.5125 29.1364C35.7547 35.8084 28.4913 39.5812 25.6226 40.8388C22.7637 39.5812 15.4713 35.77 13.7231 29.1364C12.0715 22.858 11.5499 17.3092 11.3857 14.17L25.613 10.8772ZM25.613 7.2292L7.74434 11.3668C7.74434 11.3668 7.53185 19.7092 10.2556 30.0388C12.9794 40.3684 25.6033 44.6692 25.6033 44.6692C25.6033 44.6692 38.2273 40.3684 40.9511 30.0388C43.6748 19.7092 43.4623 11.3668 43.4623 11.3668L25.613 7.2292ZM28.6015 25.0661V30.7331H23.2935V25.0436C22.1489 24.2244 21.3858 22.9002 21.3858 21.3853C21.3858 18.8716 23.4282 16.8292 25.9419 16.8292C28.4556 16.8292 30.498 18.8716 30.498 21.3853C30.498 22.9115 29.7461 24.2469 28.5902 25.0661H28.6015Z"
      />
    </g>
  </svg>
</template>
